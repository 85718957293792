import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import logo from "../assets/images/whro.png";
import mobileToggle from "../assets/images/list.svg";
import mobileToggleB from "../assets/images/list-black.svg";
import close from "../assets/images/x.svg";
import { HashLink } from "react-router-hash-link";

function Nav() {
  const [isSticky, setIsSticky] = useState(false);
  const [isMobileNavOpen, setIsMobileNavOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(null);

  const handleNavLinkClick = () => {
    setIsMobileNavOpen(!isMobileNavOpen);
  };

  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;
      if (offset > 50) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const toggleMobileNav = () => {
    setIsMobileNavOpen(!isMobileNavOpen);
  };

  const handleMouseEnter = (index) => {
    setDropdownOpen(index);
  };

  const handleMouseLeave = () => {
    setDropdownOpen(null);
  };

  return (
    <nav
      className={`w-full py-3 shadow-md ${
        isSticky
          ? "sticky top-0 z-50 bg-green-900 text-footer-white"
          : "bg-white text-black"
      } `}
    >
      <div className="container">
        <div className="flex w-full max-w-screen-lg mx-auto items-center h-full">
          <div className="h-4 w-4 lg:hidden">
            <img
              className=""
              src={isSticky ? mobileToggle : mobileToggleB}
              alt="mobile toggle"
              onClick={toggleMobileNav}
            />
          </div>
          <img
            className="h-10 mobile-logo mx-auto lg:hidden"
            src={logo}
            alt="mobile toggle"
          />

          <Link to="/" className="font-semibold hidden lg:block">
            <img className="w-28 m-0" alt="WHRO Logo" src={logo} />
          </Link>

          <ul className="hidden lg:flex gap-x-5 justify-end lg:items-center ml-auto h-[69.563px]">
            <li className="h-full flex items-center">
              <Link to="/" className="font-semibold">
                HOME
              </Link>
            </li>
            <li
              className="relative flex items-center h-full"
              onMouseEnter={() => handleMouseEnter(0)}
              onMouseLeave={handleMouseLeave}
            >
              <Link to={"/about"} className="font-semibold">
                ABOUT
              </Link>
              {dropdownOpen === 0 && (
                <div
                  className={`absolute -left-2 top-full p-2 z-10 w-[200px] ${
                    isSticky
                      ? " bg-green-900 text-footer-white"
                      : "bg-white text-black"
                  }`}
                >
                  <div className="py-1">
                    <HashLink to="/about/#whoweare" className="font-semibold">
                      WHO WE ARE
                    </HashLink>
                  </div>
                  <div className="py-1">
                    <HashLink to="/about/#mission" className="font-semibold">
                      MISSION
                    </HashLink>
                  </div>
                  <div className="py-1">
                    <HashLink to="/about/#mission" className="font-semibold">
                      VISION
                    </HashLink>
                  </div>
                  <div className="py-1">
                    <HashLink to="/about/#objectives" className="font-semibold">
                      OBJECTIVES
                    </HashLink>
                  </div>
                  <div className="py-1">
                    <Link to="/team" className="font-semibold">
                      OUR TEAM
                    </Link>
                  </div>
                </div>
              )}
            </li>
            <li
              className="relative flex items-center h-full"
              onMouseEnter={() => handleMouseEnter(1)}
              onMouseLeave={handleMouseLeave}
            >
              <Link className="font-semibold">OUR WORK</Link>
              {dropdownOpen === 1 && (
                <div
                  className={`absolute -left-2 top-full p-2 z-10 w-[200px] ${
                    isSticky
                      ? " bg-green-900 text-footer-white"
                      : "bg-white text-black"
                  }`}
                >
                  <div className="py-1">
                    <Link to="/services" className="font-semibold">
                      SERVICES
                    </Link>
                  </div>
                  <div className="py-1">
                    <Link to="/projects" className="font-semibold">
                      PROJECTS
                    </Link>
                  </div>
                  <div className="py-1">
                    <Link to="/programmes" className="font-semibold">
                      PROGRAMMES
                    </Link>
                  </div>
                </div>
              )}
            </li>
            {/* <li
              className="relative flex items-center h-full"
              onMouseEnter={() => handleMouseEnter(2)}
              onMouseLeave={handleMouseLeave}
            >
              <Link className="font-semibold">RESOURCES</Link>
              {dropdownOpen === 2 && (
                <div
                  className={`absolute -left-2 top-full p-2 z-10 w-[200px] ${
                    isSticky
                      ? " bg-green-900 text-footer-white"
                      : "bg-white text-black"
                  }`}
                >
                  <div className="py-1">
                    <Link to="/blogs" className="font-semibold">
                      NEWS
                    </Link>
                  </div>
                  <div className="py-1">
                    <Link to="/publications" className="font-semibold">
                      RESEARCH
                    </Link>
                  </div>
                </div>
              )}
            </li> */}
            <li className="h-full flex items-center">
              <Link to="/publications-list" className="font-semibold">
                RESEARCH
              </Link>
            </li>
            <li className="h-full flex items-center">
              <Link to="/blogs" className="font-semibold">
                NEWS
              </Link>
            </li>
            {/* <li className="h-full flex items-center">
              <Link to="/gallery-list" className="font-semibold">
                GALLERY
              </Link>
            </li> */}
             <li
              className="relative flex items-center h-full"
              onMouseEnter={() => handleMouseEnter(2)}
              onMouseLeave={handleMouseLeave}
            >
              <Link className="font-semibold">GALLERY</Link>
              {dropdownOpen === 2 && (
                <div
                  className={`absolute -left-2 top-full p-2 z-10 w-[200px] ${
                    isSticky
                      ? " bg-green-900 text-footer-white"
                      : "bg-white text-black"
                  }`}
                >
                  <div className="py-1">
                    <Link to="/gallery-list" className="font-semibold">
                      IMAGES
                    </Link>
                  </div>
                  <div className="py-1">
                    <Link to="/socials" className="font-semibold">
                      SOCIALS
                    </Link>
                  </div>
                </div>
              )}
            </li>
            <li className="h-full flex items-center">
              <Link to="/donate" className="font-semibold">
                DONATE
              </Link>
            </li>
            <li className="h-full flex items-center">
              <Link to="/contact" className="font-semibold">
                CONTACT
              </Link>
            </li>
          </ul>
        </div>

        {/* Mobile Navigation  */}
        <div
          className={`w-[70%] md:w-[40%] py-5 top-0 left-0 fixed px-5 bg-green-900 h-screen ${
            isMobileNavOpen ? "" : "hidden"
          } z-[100] text-footer-white`}
        >
          <ul className="w-full flex flex-col gap-y-5 justify-between">
            <div className="flex">
              <Link to="/" className="font-semibold">
                <img className="h-10 m-0" alt="WHRO Logo" src={logo} />
              </Link>
              <div className="h-4 w-4 mt-[2px] lg:hidden ml-auto">
                <img
                  className=""
                  src={close}
                  alt="mobile toggle"
                  onClick={toggleMobileNav}
                />
              </div>
            </div>
            <li>
              <Link
                to="/"
                className="font-semibold"
                onClick={handleNavLinkClick}
              >
                HOME
              </Link>
            </li>
            <li>
              <Link
                to="/about"
                className="font-semibold"
                onClick={handleNavLinkClick}
              >
                ABOUT
              </Link>
            </li>
            <li>
              <Link
                to="/services"
                className="font-semibold"
                onClick={handleNavLinkClick}
              >
                SERVICES
              </Link>
            </li>
            <li>
              <Link
                to="/projects"
                className="font-semibold"
                onClick={handleNavLinkClick}
              >
                PROJECTS
              </Link>
            </li>
            <li>
              <Link
                to="/programmes"
                className="font-semibold"
                onClick={handleNavLinkClick}
              >
                PROGRAMMES
              </Link>
            </li>
            <li>
              <Link
                to="/publications-list"
                className="font-semibold"
                onClick={handleNavLinkClick}
              >
                RESEARCH
              </Link>
            </li>
            <li>
              <Link
                to="/contact"
                className="font-semibold"
                onClick={handleNavLinkClick}
              >
                CONTACT US
              </Link>
            </li>
            <li>
              <Link
                to="/blogs"
                className="font-semibold"
                onClick={handleNavLinkClick}
              >
                NEWS
              </Link>
            </li>
            <li>
              <Link
                to="/gallery-list"
                className="font-semibold"
                onClick={handleNavLinkClick}
              >
                GALLERY
              </Link>
            </li>
            <li>
              <Link
                to="/socials"
                className="font-semibold"
                onClick={handleNavLinkClick}
              >
                SOCIALS
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default Nav;
