import React, { useEffect } from "react";

const ElfsightWidget = () => {
  useEffect(() => {
    // Check if the script is already added to avoid duplicates
    if (!document.getElementById("elfsight-platform")) {
      const script = document.createElement("script");
      script.id = "elfsight-platform"; // Assign an ID to the script
      script.src = "https://static.elfsight.com/platform/platform.js";
      script.async = true;
      document.body.appendChild(script); // Add script to the body
    }
  }, []); // Empty dependency array ensures this runs only once

  return (
    <div
      className="elfsight-app-9d875bb8-07f1-4529-8a61-9096afadfb19 w-full"
      data-elfsight-app-lazy
    ></div>
  );
};

export default ElfsightWidget;
