// import biodiversity from "../assets/images/biodiversity.png";
// import environmental from "../assets/images/environmental.png";
import internship from "../assets/images/internship2.png";
import cleanup from "../assets/images/cleanup.jpg";
import turtles from "../assets/images/turtles.jpg";
import ysp8 from "../assets/images/ysp/11.jpg";
import ed1 from "../assets/images/eaa/19.jpg";
import ab25 from "../assets/images/sb/25.jpg";
import v15 from "../assets/images/vultures/15.jpg";
import environmental from "../assets/images/environmental.png";
import m2 from "../assets/images/mangrove/2.png";
import t7 from "../assets/images/training/7.png";
import s13 from "../assets/images/support/13.jpg";
import fb7 from "../assets/images/fb/7.jpg";
import { Link } from "react-router-dom";
import MetaTags from "../components/MetaTags";
import Header from "../components/Header";
import header from "../assets/images/news.jpg";
import ElfsightWidget from "../components/ElfSight";

function Socials() {
  return (
    <>
      <MetaTags
        title="Social - WHRO"
        keywords="Instagram, Socials"
        canonical="https://www.whroghana.org/socials"
      />
      <Header
        img={header}
        subHeading="SOCIALS"
        mainHeading="Relive All Our Stories"
      />
      <div className="team-display-section bg- py-14 md:py-16 lg:py-32">
        <div className="container">
          <ElfsightWidget/>
        </div>
      </div>
    </>
  );
}

export default Socials;
